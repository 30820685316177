/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/navigation-languages';

import './modules/deep-linking';

import './modules/init-aos';

import './modules/boxes';
