/**
 * Navigation Languages
 */

const $itemsLength = $('.nav-lang li').length;

if ($itemsLength === 1) {
	$('.nav-lang').addClass('one-item-only');
}

$('.nav-lang li').on('click', function(e){
	const $this = $(this);

	if (!$('.nav-lang').hasClass('selected')) {
		e.preventDefault();

		$('.nav-lang').addClass('selected');

		$this
			.parents('.nav-lang')
				.toggleClass('open');

		$this
			.parent()
				.addClass('active')
			.siblings()
				.removeClass('active');
	}
});
