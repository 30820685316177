$('.boxes .box').on('click', function(){
	const $this = $(this);
	const $parent = $this.parent();

	if ( !$parent.hasClass('active')) {
		$parent
			.addClass('active')
			.siblings()
				.removeClass('active');
	}
})
